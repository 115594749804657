import React, { useEffect } from 'react';
import Image from 'gatsby-plugin-sanity-image';
import { Link, graphql } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

import { BackgroundVideo } from '../components/media/backgroundVideo';

const cardVars = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: 60 },
};

const ProjectCard = ({ title, subheading, portalImage, slug }) => {
  const [ref, inView] = useInView({ threshold: 0.3 });
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <Link
      to={`/projects/${slug && slug.current}`}
      className="col-span-2 md:col-span-1 group"
      ref={ref}
    >
      <motion.div
        className="aspect-w-12 aspect-h-13 relative"
        initial="hidden"
        animate={controls}
        transition={{ duration: 1, ease: 'easeOut' }}
        variants={cardVars}
      >
        {portalImage && portalImage.asset && (
          <Image {...portalImage} className="absolute top-0 left-0 object-cover" />
        )}
      </motion.div>
      <motion.div
        className="mt-6"
        initial="hidden"
        animate={controls}
        transition={{ duration: 1, ease: 'easeOut', delay: 0.2 }}
        variants={cardVars}
      >
        <h2 className="text-3xl md:text-4xl mb-2">{title}</h2>
        <p className="text-lg">{subheading}</p>
      </motion.div>
    </Link>
  );
};

const FeatureCard = ({ title, subheading, portalImage, portalVideo, slug }) => {
  const [ref, inView] = useInView({ threshold: 0.3 });
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <Link className="col-span-2" to={`/projects/${slug && slug.current}`} ref={ref}>
      <motion.div
        className="aspect-w-12 aspect-h-13 lg:aspect-w-16 lg:aspect-h-8 relative"
        initial="hidden"
        animate={controls}
        transition={{ duration: 1, ease: 'easeOut', delay: 0.2 }}
        variants={cardVars}
      >
        {portalVideo ? (
          <BackgroundVideo video={portalVideo} />
        ) : (
          <>
            {portalImage && portalImage.asset && (
              <Image {...portalImage} className="absolute top-0 left-0 object-cover" />
            )}
          </>
        )}
      </motion.div>
      <motion.div
        className="mt-6"
        initial="hidden"
        animate={controls}
        transition={{ duration: 1, ease: 'easeOut', delay: 0.2 }}
        variants={cardVars}
      >
        <h2 className="text-3xl md:text-4xl mb-2">{title}</h2>
        <p className="text-lg">{subheading}</p>
      </motion.div>
    </Link>
  );
};

const ProjectsPage = ({ data: { allSanityProject } }) => {
  const projects = allSanityProject.edges || [];

  useEffect(() => {
    document.documentElement.classList.remove('themeDark');
  }, []);

  return (
    <>
      <div className="px-gutter grid grid-cols-2 gap-10 py-32 lg:gap-24">
        {projects.map(({ node }, index) =>
          index > 0 ? <ProjectCard {...node} /> : <FeatureCard {...node} />
        )}
      </div>
    </>
  );
};

export default ProjectsPage;

export const pageQuery = graphql`
  query projectsQuery {
    allSanityProject(sort: { fields: order }) {
      edges {
        node {
          title
          subheading
          portalImage {
            ...ImageWithPreview
          }
          portalVideo
          slug {
            current
          }
        }
      }
    }
  }
`;

import React from 'react';
import ReactPlayer from 'react-player';

import { wrapper } from './backgroundVideo.module.css';

export const BackgroundVideo = ({ video, className }) => {
  return (
    <div className={`${wrapper} ${className}`}>
      <ReactPlayer url={video} playing={true} muted loop playsinline />
    </div>
  );
};
